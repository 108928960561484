/* eslint-disable @atlaskit/ui-styling-standard/no-styled */
/* eslint-disable @atlaskit/ui-styling-standard/no-exported-styles */
import { styled, css, keyframes } from '@compiled/react';

import { token } from '@atlaskit/tokens';

export const RESPONSIVE_CONTENT_WIDTH_THRESHOLD = 1129;

const spinAnimation = keyframes({
	'0%': {
		transform: 'rotateZ(270deg)',
		opacity: 1,
	},
	'50%': {
		transform: 'rotateZ(-45deg)',
		opacity: 1,
	},
	'65%': {
		transform: 'rotateZ(30deg)',
		opacity: 1,
	},
	'80%': {
		transform: 'rotateZ(-20deg)',
		opacity: 1,
	},
	'90%': {
		transform: 'rotateZ(10deg)',
		opacity: 1,
	},
	'100%': {
		transform: 'rotateZ(0)',
		opacity: 1,
	},
});

const zoomAndSpinAnimation = keyframes({
	'0%': {
		transform: 'rotateZ(270deg) scale(1, 1)',
		opacity: 1,
	},
	'50%': {
		transform: 'rotateZ(-45deg) scale(2, 2)',
		opacity: 1,
	},
	'62.5%': {
		transform: 'rotateZ(30deg) scale(1.75, 1.75)',
		opacity: 1,
	},
	'75%': {
		transform: 'rotateZ(-20deg) scale(1.5, 1.5)',
		opacity: 1,
	},
	'87.5%': {
		transform: 'rotateZ(10deg) scale(1.25, 1.25)',
		opacity: 1,
	},
	'100%': {
		transform: 'rotateZ(0) scale(1, 1)',
		opacity: 1,
	},
});

const zoomAnimation = keyframes({
	'0%': {
		transform: 'scale(1, 1)',
		opacity: 1,
	},
	'14%': {
		transform: 'scale(1.35, 1.35)',
		opacity: 1,
	},
	'18%': {
		transform: 'scale(1.25, 1.25)',
		opacity: 1,
	},
	'22%': {
		transform: 'scale(1.32, 1.32)',
		opacity: 1,
	},
	'26%': {
		transform: 'scale(1.3, 1.3)',
		opacity: 1,
	},
	'80%': {
		transform: 'scale(1.3, 1.3)',
		opacity: 1,
	},
	'100%': {
		transform: 'scale(1, 1)',
		opacity: 1,
	},
});

const Design1 = css({
	animationName: spinAnimation,
});

const Design2 = css({
	animationName: zoomAndSpinAnimation,
});

const Design3 = css({
	animationName: zoomAnimation,
	animationDuration: '3s',
	animationFillMode: 'both',
	animationIterationCount: '1',
});

export const IconWrapper = styled.div<{ experiment: string; isReducedMotion: boolean }>(
	{
		display: 'flex',
		marginLeft: token('space.negative.050'),
		animationDuration: '1s',
		animationFillMode: 'both',
		animationIterationCount: '1',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ experiment, isReducedMotion }) => experiment === 'design_1' && !isReducedMotion && Design1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ experiment, isReducedMotion }) => experiment === 'design_2' && !isReducedMotion && Design2,
);

export const CreateContentButtonWrapper = styled.div<{
	experiment: string;
	isReducedMotion: boolean;
}>(
	{
		marginLeft: token('space.150', '12px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'button, button:hover, button:focus': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			[`@media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px)`]: {
				width: '32px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
				'& > span:first-of-type': {
					width: '24px',
					marginLeft: token('space.150', '12px'),
					marginRight: '0',
				},
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ experiment, isReducedMotion }) => experiment === 'design_3' && !isReducedMotion && Design3,
);
