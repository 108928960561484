import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';

export const useCreateSpaceExperiment = () => {
	// Enable the new space types creation flow for users who have live pages enabled.
	const isLivePagesEnabled = useIsLivePagesFeatureEnabled();

	// This gate is used for EAP and predates the space types experiment
	// https://hello.atlassian.net/wiki/spaces/OST/pages/3085861343/Space+Types+EAP+Scope
	const isEapGateEnabled = FeatureGates.checkGate('space_types_creation_flow');

	// This is for the space types experiment, which has a broader audience than the EAP
	// https://hello.atlassian.net/wiki/spaces/OST/pages/3594131891/Experiment+Space+Types+Creation+Experience
	const experimentCohort = FeatureGates.getExperimentValue<string>(
		'space_types_experiment',
		'cohort',
		'control',
	);

	// Both EAP and Variant-1 cohorts get the new creation flow.
	// Note that EAP users are guaranteed to have live pages enabled, while the experiment cohort may not have live pages.
	const isSpaceTypesCreationFlowEnabled =
		isEapGateEnabled || experimentCohort === 'variant1' || isLivePagesEnabled;

	return { isSpaceTypesCreationFlowEnabled };
};
